<!--
 * @Author: your name
 * @Date: 2021-12-07 15:40:36
 * @LastEditTime: 2023-02-10 15:56:58
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 联系我们
 * @FilePath: \new-website-1123\src\views\service\contactus\index.vue
-->
<template>
  <div class="service-contactus">
    <div
      class="contactus-us"
      :style="{
        backgroundImage: 'url(' + banner + ')',
        'background-size': 'cover',
      }"
    >
      <div class="contactus-us-view">
        <ul class="contactus-us-infor">
          <li v-for="(item, index) in conntact" :key="index">
            <h4>{{ item.label }}</h4>
            <h6>{{ item.label_text }}</h6>
            <h6 class="phonetwo">{{ item.label_text2 }}</h6>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "service-contactus",
  components: {},
  data() {
    return {
      banner: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/service/contactus-pic.jpg",
      conntact: [
        {
          label: "研发中心地址",
          label_text: "四川省成都市成华区建设路9号高地中心",
        },
        {
          label: "服务热线",
          label_text: "400-6599-888",
          label_text2: "18980599999",
        },
        {
          label: "网址",
          label_text: "www.stkjiot.com",
        },
        {
          label: "邮箱",
          label_text: "stkjadmin@stkjiot.com",
        },
      ],
    };
  },
  created() {},
  computed: {
    currentDevice() {
      return this.deviceList.filter((item) => item.key == this.deviceActive)[0];
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.contactus-us {
  height: 880px;

  .contactus-us-view {
    width: 1200px;
    padding-top: 196px;
    margin: 0 auto;
    .contactus-us-infor {
      color: $color-text;
      h4 {
        font-size: 16px;
        margin-bottom: 16px;
        font-weight: normal;
      }
      h6 {
        color: $color-active;
        font-size: 26px;
        margin-bottom: 40px;
        font-weight: normal;
      }
      .phonetwo {
        margin-top: -30px;
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .contactus-us {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right -2.82rem top;
    height: 7.27rem;
    .contactus-us-view {
      width: 86.4%;
      padding-top: 1.55rem;
      .contactus-us-infor {
        h4 {
          font-size: 0.22rem;
          margin-bottom: 0.22rem;
        }
        h6 {
          font-size: 0.27rem;
          margin-bottom: 0.36rem;
        }
        .phonetwo {
          margin-top: -0.27rem;
        }
      }
    }
  }
}
</style>
